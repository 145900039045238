<template>
  <div>
    <!-- <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Dashboard"
      icon="BarChartIcon"
      size="15"
      class="mr-1"
      @click="$router.push(`/usuarios/dashboard/${item.member_id}`)"
    /> -->
    <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Editar Usuário"
      icon="EditIcon"
      size="15"
      @click="$router.push(`/update-user/${item.id}`)"
    />
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
export default {
  name: 'actions-user',
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    item: { type: Object, default: () => {} },
  },

};
</script>
